@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(169, 169, 169, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(169, 169, 169, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(169, 169, 169, 0);
  }
}
.bg {
  --ion-background-color: linear-gradient(180deg, #FF8744 100%, rgba(255, 137, 137, 0.59) 54.17%, #DE7A7A 100%) !important;

  }


.modal { 
  --ion-background-color: linear-gradient(180deg, #FF8744 0.25%, rgba(255, 137, 137, 0.59) 54.17%, #DE7A7A 100%) !important;

}


.toasts{
  --background:white !important;
  --button-color:#FF8744 !important;
}

.submit-enter {
  position: absolute;
  visibility: hidden;
  left: -1000px;
}

.transparentbg {
  background: transparent !important;
}

ion-input{
	color: #cf6830 !important; 
  font-weight: 700;
}


.input-clear-icon.sc-ion-input-md,
.input-clear-icon.sc-ion-input-ios  {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><polygon fill='white' points='405,136.798 375.202,107 256,226.202 136.798,107 107,136.798 226.202,256 107,375.202 136.798,405 256,285.798 375.202,405 405,375.202 285.798,256'/></svg>") !important;
  width: 30px;
  height: 30px;
  background-size: 22px;
}


ion-item {
  color: white !important; 
	--background:transparent !important;
  --highlight-color-focused: #b85e2e !important;
  --background-focused-opacity: 0% !important; 	
  --border-color: #b85e2e !important;
  --background-hover-opacity: 0% !important; 

  --background-focused: transparent !important; 	

  --background-activated-opacity: 0% !important; 
}

.toolbar {
  --background: transparent !important;
  --border-color: transparent !important;
  --border: transparent !important;
  background: #FF8744 !important;
}


.modal .modal-content {
  --background: transparent !important;
  --border-color: transparent !important;
  --border: transparent !important;
}
